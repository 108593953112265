import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Logo = props => {
    const image = useStaticQuery(graphql`
        query {
            default: file(
                relativePath: { eq: "logos/academia/logo-iopos-squared-transparent.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            positive: file(
                relativePath: { eq: "logos/academia/logo-iopos-squared-white-transparent.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const getImage = () => {
        if (!props.type) {
            return image.default.childImageSharp.fluid;
        } else {
            if (props.type === "default") {
                return image.default.childImageSharp.fluid;
            } else {
                return image.positive.childImageSharp.fluid;
            }
        }
    };

    return (
        <Img
            fadeIn={true}
            fluid={getImage()}
            className={`${props.className}`}
            alt={`${props.alt || "iopos logo"}`}
        />
    );
};
export default Logo;
