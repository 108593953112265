import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ description, lang, meta, title, author, image }) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaDescription = description || site.siteMetadata.description;
    const metaAuthor = author || site.siteMetadata.author || "@iopos";
    const metaImage = image || "/images/logos/iopos-favicon.png";

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            link={[
                {
                    rel: "canonical",
                    href: "https://www.iopos.es",
                },
            ]}
            title={title}
            titleTemplate={`%s » ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `author`,
                    content: metaAuthor,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: metaAuthor,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: metaImage,
                },
            ].concat(meta)}
        />
    );
};

SEO.defaultProps = {
    lang: `es`,
    description: `Prepárate para Mossos, Guardia Urbana, Policía Local, Guardia Civil, Sanitarios y Correos en Barcelona. Formación rápida y de calidad con garantía de éxito. ¡Inscríbete ya!`,
    meta: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    author: PropTypes.string,
};

export default SEO;
